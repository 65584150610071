.header_menu {
  width: 100%;
}

.header_left {
  width: 90%;
  float: left;
  text-align: left;
}

.icon {
  width: 10%;
  // opacity: 100%;
}

.icon_disabled {
  // opacity: 30%;
  width: 10%;
}

.favorite_icon {
  height: 35px;
  cursor: pointer;
  padding: 0 !important
}

.description {
  font-size: large;
}

.comments {
  background-color: red;
  padding: 5px;
  border-radius: 10px;
}

.error_message {
  color: red;
}

.rows {
  display: inline-block;
}

.row {
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.last_updated{
  color: grey;
  font-size: small;
}
.bold{
  font-weight: bolder;
}
.content{
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%;
  padding-top: 1%;
}

.sections{
  background-color: red;
}

.teams_section{
  padding-top: 2%;
}

.app_header{
  padding: 2rem;
  color: white;
}
.app_details{
  padding: 2rem;
  color: white;
}

.white_font{
  color: white;
  font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif;
}

.ag-grid-container {
  display: flex;
  height: calc(75vh - 50px);
}

.edited-row {background-color: rgb(238, 238, 238);}
.edited-cell {font-weight: bolder;}
.editable-cell {background-color: lightyellow;}
.disabled-row {
  opacity: 0.9;
  cursor: not-allowed;
  pointer-events: none;
  background-color: #f8f9fa;
  color: #6c757d;
  position: relative;
}
.new-row{
  font-weight: bolder;
}
.annotatonUploadModal{
  background-color: #6c757d;
  width: 1000px;
}
.bold-text{
  font-weight: bold;
}
.agg-text{
  font-size: small;
}
