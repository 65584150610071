.red_badge {
  background-color: red;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

.green_badge {
  background-color: green;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

.orange_badge {
  background-color: orange;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

.blue_badge {
  background-color: blue;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

.grey_badge {
  background-color: grey;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

.is-disabled {
  pointer-events: none;
  opacity: 0.5;
  color: black;
}

.enabled{
  pointer-events:all
}

.clickable {
  cursor: pointer;
}

.icon {
  height: 2rem;
  cursor: pointer;
  padding: 0 !important;
  width: 2rem;
}

.orangeText {
  color: orange;
}

.small_badge {
  font-size: small;
}

.briefContent{
  display: flex;
  justify-content: center;
  align-items: center;

}
.container {
  display: flex; /* or inline-flex */
}

.col {
  flex-grow: 1;
}

.col2x {
  flex-grow: 2;
}
.briefContentTextIncomplete{
  color: red;
  font-size: large;
}
.weightBold{
  font-weight: bold;
}
.weightBolder{
  font-weight: bolder;
}
.briefContentTextComplete{
  color: green;
  font-size: large;
}
.pointer{
  cursor: pointer;
}
.paddingx{
  padding: 1rem;
}

.rounded-circle-small{
  border-radius: 50% ;
  width: 2rem ;
  height: 2rem ;
}

.white-text{
  color: white;
}

.mainPage{
  padding-left: 5%;
  padding-right: 5%;
}

.errorMessage{
  color: red;
}

.small_grey_text_italics{
  color: grey;
  font-size: small;
  font-style: italic;
}
/* Rounded border */
hr.rounded {
  margin-top: 1rem;
  border-width:0;
  height: 1px;
  width: 90%;
  background-color:lightgray;
}

.small_label {
  font-size: smaller;
  font-weight: bold;
}

.small_values{
  font-size: smaller;
}