.mainPage{
    padding-left: 5%;
    padding-right: 5%;
}

.errorMessage{
    color: red;
}

.custom-new-upload__error-message {
    margin-top: 2rem;
    color: red;
}
.missing{
    color: red;
}
.available{
    color: black;
}

.last_updated{
  color: grey;
  font-size: small;
}

.small_grey_text{
    color: grey;
    font-size: small;
}

.small_grey_text_italics{
    color: grey;
    font-size: small;
    font-style: italic;
}

.content{
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
    padding-top: 1%;
  }

  .params{
    box-shadow: 1px 0 0 lightgrey inset;
    padding-left: 4rem;
  }