.error-message {
    color: red;
}

.dp {
  object-fit: cover;
  border-radius: 30% ;
    width: 6rem ;
    height: 6rem ;
}

.xxsmall-grey{
  color: grey;
  font-size: xx-small;
}