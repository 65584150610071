.mainPage{
    padding-left: 5%;
    padding-right: 5%;
}

.errorMessage{
    color: red;
}

.orangeMessage{
  color: orange;
}
.greenMessage{
  color: green;
}
.custom-new-upload__error-message {
    margin-top: 2rem;
    color: red;
}
.missing{
    color: red;
}
.available{
    color: black;
}

.last_updated{
  color: grey;
  font-size: small;
}

.small_grey_text{
    color: grey;
    font-size: small;
}

.small_grey_text_italics{
    color: grey;
    font-size: small;
    font-style: italic;
}

.content{
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
    padding-top: 1%;
  }

  .red_badge {
    background-color: red;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer
  }
  
  .green_badge {
    background-color: green;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer
  }
  
  .orange_badge {
    background-color: orange;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer
  }
  
  .blue_badge {
    background-color: blue;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer
  }
  
  .grey_badge {
    background-color: grey;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer
  }
  