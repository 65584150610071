@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

// div.section {
//     &:first-of-type {
//         margin-top: 30px;
//     }
//     margin-bottom: 30px;
//     opacity: 1;

//     &.hidden {
//         opacity: 0;
//     }

//     &:not(.hidden) {
//         animation-name: fadeIn;
//         animation-duration: 100ms;
//     }
// }

// .foo { flex: 1; }

// @keyframes fadeIn {
//     from {
//         opacity: 0;
//     }
//     to {
//         opacity: 1;
//     }
// }

// .float-right {
//     float: right;
// }


.custom-home__header {
    background-color: awsui.$color-background-home-header;
  }
  
  .custom-home__header-title {
    color: awsui.$color-text-home-header-default;
  }
  
  .custom-home__category,
  .custom-home__header-sub-title {
    color: awsui.$color-text-home-header-secondary;
  }
  
  @media (min-width: 992px) {
    .custom-home__sidebar {
      margin-top: -6rem;
    }
  }
  
  .custom-home__header-title > * {
    max-width: 70rem;
  }
  
  .custom-home-image__placeholder:before {
    content: "X";
    display: block;
    background-color: awsui.$color-background-layout-main;
    color: awsui.$color-background-container-content;
    text-align: center;
    font-size: 40rem;
    line-height: 40rem;
  }
  
  ul.custom-list-separator {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  ul.custom-list-separator li {
    border-top: 1px solid awsui.$color-border-divider-default;
    padding: 0.8rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  ul.custom-list-separator li:first-child {
    padding-top: 0;
    border-top: none;
  }
  
  ul.custom-list-separator li:last-child {
    padding-bottom: 0;
  }

  .card-list {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
  }

  .list-item .link-tag {
    display: block;
    border: 2px solid black;
    border-radius: 2rem;
    padding-right: 6rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    padding-left: 2rem;
    width: 140px;
    margin:10px ;
    color: inherit;
    text-decoration: none;
    text-decoration-style: none;
    color: black;
  }
  
  .list-item .link-tag:visited {
    text-decoration: none;
    color: black;
  }

  .list-item a:hover, .list-item a:link:hover, .list-item a:visited:hover {
    background-color: #28a745;
    text-decoration: none;
    color: white;
    transition: background-color 0.25s ease-in-out;
    cursor: pointer;
  }

  .rounded-circle{
    border-radius: 50% ;
    width: 4rem ;
    height: 4rem ;
  }

  .phoneToolParent {
    height: inherit;
    display: flex;
    align-items: center;
  }

  .ag-cell .rounded-circle {
    height: 2rem;
    width: 2rem;
  }

  #h {
    display: block;
    position: sticky;
    height: 55px;
  
    z-index: 1002;
  
    top: 0;
    left: 0;
    right: 0;
  
    margin: 0;
    margin-left: -20px;
    font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif;
  }

  .list-item-data {
    list-style-type: none;
  }

  .qs_dash{
    border: 0px;
    width: 100%;
    height: 720px;
  }