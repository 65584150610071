.inline{
    display:flex; flex-direction: row; justify-content: center; align-items: center
}
.info{
    color: grey;
    font-size: small;
    font-style: italic;
  }
.mandatory{
    color: red;
    font-size: large;
    font-weight: bold;
}

.error{
    color: red;
}

.duplicate{
    border-color: red;
    border-style: solid;
}
.param_input{
    width: 30rem;
}
.param_input_dynamic{
    width: 73rem;
}
.line{
    width: 112px;
    height: 47px;
    border-bottom: 1px solid black;
    position: absolute;
    }
.hintMessage{
    color: grey;
    font-size: small;
}